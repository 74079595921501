import {
  APIError,
  AuthError,
  BadRequestError,
  InvalidDataCsvError,
} from "@/services/error_service";

import { UtilityAPIResponse } from "@/models/api_res";
import Vue from "vue";
import axios from "axios";

/**
 * Creates a new Utility Service

 * @example
 * const utilityService = new UtilityService();
 * utilityService.getutility();
 */

export default class UtilityService {
  /**
   * POST to utility server
   * @param {string} code
   * @returns {void}
   */
  public async uploadProductCsv(
    file: File,
    bu: string,
    isPart = false
  ): Promise<UtilityAPIResponse> {
    let data = {} as UtilityAPIResponse;
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };
    const body = new FormData();
    body.append("file", file);
    let url: string =
      process.env.VUE_APP_API_URL_NODE + `/import/products?bu=${bu}`;

    if (isPart) {
      url += "&part=true";
    }
    try {
      const res = await axios.post(url, body, options);
      data = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 422) {
        throw new InvalidDataCsvError(err.response.data.message);
      } else if (err.response && err.response.status === 400) {
        throw new BadRequestError(err.response.data.message);
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return data;
  }

  public async uploadDimCsv(file: File): Promise<UtilityAPIResponse> {
    let data = {} as UtilityAPIResponse;
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };
    const body = new FormData();
    body.append("file", file);
    try {
      const res = await axios.post(
        process.env.VUE_APP_API_URL + "/import/dimensions",
        body,
        options
      );
      data = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 422) {
        throw new InvalidDataCsvError(err.response.data.message);
      } else if (err.response && err.response.status === 400) {
        throw new BadRequestError(err.response.data.message);
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return data;
  }

  public async uploadShippingCsv(file: File): Promise<UtilityAPIResponse> {
    let data = {} as UtilityAPIResponse;
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };
    const body = new FormData();
    body.append("file", file);
    try {
      const res = await axios.post(
        process.env.VUE_APP_API_URL + "/import/shipping",
        body,
        options
      );
      data = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 422) {
        throw new InvalidDataCsvError(err.response.data.message);
      } else if (err.response && err.response.status === 400) {
        throw new BadRequestError(err.response.data.message);
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return data;
  }

  public async uploadDownloadsCsv(file: File): Promise<UtilityAPIResponse> {
    let data = {} as UtilityAPIResponse;
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };
    const body = new FormData();
    body.append("file", file);
    try {
      const res = await axios.post(
        process.env.VUE_APP_API_URL_NODE + "/import/downloads",
        body,
        options
      );
      data = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 422) {
        throw new APIError(err.response.data.message);
      } else if (err.response && err.response.status === 400) {
        throw new BadRequestError(err.response.message);
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return data;
  }
}
