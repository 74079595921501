
import { Component, Vue, Prop } from "vue-property-decorator";
import UtilityService from "@/services/utility_service";

@Component
export default class Part extends Vue {
  @Prop() selectedFile: File | null = null;
  protected utilityService = new UtilityService();

  get selected(): string {
    let text = "No file selected.";
    if (this.selectedFile) {
      text = this.selectedFile.name;
    }
    return text;
  }

  public clear(): void {
    this.$emit("onPartFileChange", null);
  }

  public openFileManager(): void {
    (this.$refs.fileInputProduct as HTMLInputElement).click();
  }

  public selectFile(): void {
    const file = (this.$refs.fileInputProduct as HTMLInputElement).files![0];
    this.$emit("onPartFileChange", file);
  }
}
