
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import UtilityService from "@/services/utility_service";
import { UtilityAPIResponse } from "@/models/api_res";
import { AuthError } from "@/services/error_service";
import Product from "./Product.vue";
import Shipping from "./Shipping.vue";
import Dimension from "./Dimension.vue";
import Download from "./Download.vue";
import UIkit from "uikit";
import { LoaderComponent } from "node_modules/vue-loading-overlay/types/loading";
import Part from "./Part.vue";

@Component({
  components: {
    Product,
    Shipping,
    Dimension,
    Download,
    Part
  }
})
export default class Utility extends Vue {
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;

  public product: File | null = null;
  public dim: File | null = null;
  public shipping: File | null = null;
  public download: File | null = null;
  public partFile: File | null = null;
  protected utilityService = new UtilityService();
  protected showError = false;
  protected loader: LoaderComponent | null = null;
  protected errors: string[] = [];
  protected productLoader = false;
  protected shippingLoader = false;
  protected dimLoader = false;
  protected downloadLoader = false;
  protected partLoader = false;

  get sampleProduct() {
    return require("../../assets/files/test_product_import.csv");
  }

  get sampleShipping() {
    return require("../../assets/files/test_shipping_import.csv");
  }

  get sampleDim() {
    return require("../../assets/files/test_dimensions_import.csv");
  }

  get sampleDl() {
    return require("../../assets/files/test_downloads_import.csv");
  }

  get samplePart() {
    return require("../../assets/files/test_part_import.csv");
  }

  protected showSpinner(): LoaderComponent {
    const loader = this.$loading.show(
      {
        isFullPage: true,
        canCancel: false
      },
      { before: this.$createElement("h1", "Uploading...") }
    );
    return loader;
  }

  protected hideSpinner(): void {
    if (
      !this.productLoader &&
      !this.shippingLoader &&
      !this.dimLoader &&
      !this.downloadLoader &&
      this.loader
    ) {
      this.loader.hide();
    }
  }

  protected close(): void {
    this.$emit("close");
    this.errors = [];
    this.showError = false;
  }

  public productFile(file: File): void {
    this.product = file;
  }

  public dimFile(file: File): void {
    this.dim = file;
  }

  public shippingFile(file: File): void {
    this.shipping = file;
  }

  public dlFile(file: File): void {
    this.download = file;
  }

  public updatePartFile(file: File): void {
    this.partFile = file;
  }

  protected upload(): void {
    this.loader = this.showSpinner();
    this.errors = [];
    this.showError = false;

    if (!this.product && !this.shipping && !this.dim && !this.download) {
      this.hideSpinner();
      UIkit.notification(`
      <div class="other-class uk-animation-slide-top uk-alert-danger" uk-alert>
        <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
        <div class="uk-alert-danger">
          <span>No file selected.</span>
        </div>
      </div>`);
    }

    if (this.product) {
      this.productLoader = true;
      this.sendProduct(this.businessUnit);
    }
    if (this.shipping) {
      this.shippingLoader = true;
      this.sendShipping();
    }
    if (this.dim) {
      this.dimLoader = true;
      this.sendDim();
    }
    if (this.download) {
      this.downloadLoader = true;
      this.sendDownload();
    }
    if (this.partFile) {
      this.partLoader = true;
      this.sendPartFile(this.businessUnit);
    }
  }

  private async sendPartFile(bu: string): Promise<void> {
    const partRes = await this.uploadPart(bu);
    this.partLoader = false;
    if (partRes?.errors?.length) {
      partRes.errors.forEach(err => {
        this.errors.push(`<p>Part CSV error: Row ${err.line}, ${err.err}</p>`);
      });
      this.showError = true;
    } else {
      this.displayNotification("Part spreadsheet uploaded successfully");
    }
    this.hideSpinner();
  }

  protected async sendProduct(bu: string): Promise<void> {
    const productRes = await this.uploadProduct(bu);
    this.productLoader = false;
    if (productRes && productRes.errors && productRes.errors.length > 0) {
      productRes.errors.forEach(err => {
        this.errors.push(
          `<p>Product CSV error: Row ${err.line}, ${err.err}</p>`
        );
      });
      this.showError = true;
    } else if (productRes) {
      this.displayNotification("Product spreadsheet uploaded successfully");
    }
    this.hideSpinner();
  }

  protected async sendShipping(): Promise<void> {
    const shippingRes = await this.uploadShipping();
    this.shippingLoader = false;
    if (shippingRes && shippingRes.errors && shippingRes.errors.length > 0) {
      shippingRes.errors.forEach(err => {
        this.errors.push(
          `<p>Product CSV error: Row ${err.line}, ${err.err}</p>`
        );
      });
      this.showError = true;
    } else if (shippingRes) {
      this.displayNotification("Shipping spreadsheet uploaded successfully");
    }
    this.hideSpinner();
  }

  protected async sendDim(): Promise<void> {
    const dimRes = await this.uploadDim();
    this.dimLoader = false;
    if (dimRes && dimRes.errors && dimRes.errors.length > 0) {
      dimRes.errors.forEach(err => {
        this.errors.push(
          `<p>Dimension CSV error: Row ${err.line}, ${err.err}</p>`
        );
      });
      this.showError = true;
    } else if (dimRes) {
      this.displayNotification("Dimension spreadsheet uploaded successfully");
    }
    this.hideSpinner();
  }

  protected async sendDownload(): Promise<void> {
    const dlRes = await this.uploadDownloads();
    this.downloadLoader = false;
    if (dlRes && dlRes.errors && dlRes.errors.length > 0) {
      dlRes.errors.forEach(err => {
        this.errors.push(
          `<p>Download CSV error: Row ${err.line}, ${err.err}</p>`
        );
      });
      this.showError = true;
    } else if (dlRes) {
      this.displayNotification("Download spreadsheet uploaded successfully");
    }
    this.hideSpinner();
  }

  private async uploadPart(bu: string): Promise<UtilityAPIResponse | null> {
    let data = null;
    try {
      const res = await this.utilityService.uploadProductCsv(
        this.partFile as File,
        bu,
        true
      );
      data = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.errors.push(`<p>Part CSV ${err}</p>`);
        this.showError = true;
      }
    }
    this.partFile = null;
    return data;
  }

  protected async uploadProduct(
    bu: string
  ): Promise<UtilityAPIResponse | null> {
    let data = null;
    try {
      const res = await this.utilityService.uploadProductCsv(
        this.product as File,
        bu
      );
      data = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.errors.push(`<p>Product CSV ${err}</p>`);
        this.showError = true;
      }
    }
    this.product = null;
    return data;
  }

  protected async uploadDim(): Promise<UtilityAPIResponse | null> {
    let data = null;
    try {
      const res = await this.utilityService.uploadDimCsv(this.dim as File);
      data = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.errors.push(`<p>Dimension CSV ${err}</p>`);
        this.showError = true;
      }
    }
    this.dim = null;
    return data;
  }

  protected async uploadShipping(): Promise<UtilityAPIResponse | null> {
    let data = null;
    try {
      const res = await this.utilityService.uploadShippingCsv(
        this.shipping as File
      );
      data = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.errors.push(`<p>Shipping CSV ${err}</p>`);
        this.showError = true;
      }
    }
    this.shipping = null;
    return data;
  }
  protected async uploadDownloads(): Promise<UtilityAPIResponse | null> {
    let data = null;
    try {
      const res = await this.utilityService.uploadDownloadsCsv(
        this.download as File
      );
      data = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.errors.push(`<p>Download CSV ${err}</p>`);
        this.showError = true;
      }
    }
    this.download = null;
    return data;
  }

  private displayNotification(message: string): void {
    UIkit.notification(`
      <div class="other-class uk-animation-slide-top uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
        <div class="uk-alert-success">
          <span>${message}.</span>
         </div>
        </div>
      `);
  }
}
